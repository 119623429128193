import React, { useState, useEffect } from "react"
import Head from "../../../compnents/head";
import Footer from "../../../compnents/footer";
import "./index.css";
import { Table, Spin, Popover } from "antd";
import { urls, formatDate, getSubStrNum } from "../../../utils/utils";
import { Link } from "react-router-dom";
function Block() {
    const [dataSource, setdataSource] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(1);
    const [spinning, setspinning] = useState(true);
    useEffect(() => {
        getData(10, 1);
    }, [])
    const getData = (pageSize, pages) => {
        fetch(urls + `blocks/${pageSize}/${pages}`)
            .then(res => res.json())
            .then(res => {
                if (res.Desc === "SUCCESS") {
                    setdataSource(res.Result.List);
                    setTotal(res.Result.Total);
                    setPageNum(res.Result.pageNum);
                    setPageSize(res.Result.pageSize);
                    setspinning(false)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const changePage = (pages) => {
        console.log(pages)
        localStorage.setItem("blockPage", pages);
        setPageNum(pages)
        setspinning(true)
        getData(10, pages)
    }
    const columns = [
        {
            title: '块高',
            dataIndex: 'Height',
            key: 'Height',
            render: (text) => <Link to={`/block/blockDetail/${text}`} style={{ color: "rgba(0,0,0,0.8)" }}>{text}</Link>
        },
        {
            title: '大小（byte）',
            key: 'Blocksize',
            dataIndex: 'Blocksize',
        },
        {
            title: "共识数据",
            dataIndex: 'Consensusdata',
        },
        {
            title: "哈希",
            dataIndex: "Hash",
            key: "Hash",
            render: (hash) => (<Popover arrowPointAtCenter={true} autoAdjustOverflow={false} content={hash} title="区块哈希" ><span>{getSubStrNum(hash, 5)}</span></Popover>)
        },
        {
            title: '时间',
            dataIndex: "Blocktime",
            key: 'Blocktime',
            render: (time) => (<span>{formatDate(time * 1000)}</span>)
        }
    ]
    const paginationProps = {
        showSizeChanger: false,
        showTotal: () => `共${total}条`,
        current: pageNum,
        pageSize: pageSize,
        total: total,
        pageSizeOptions: ['10', '20'],
        onChange: (current) => changePage(current),

    };
    return <div className="layout">
        <div className="top-head">
            <Head />
        </div>
        <div className="list">
            <Spin spinning={spinning}>
                <h2>最新区块</h2>
                <Table
                    className="block-table"
                    columns={columns}
                    dataSource={dataSource}
                    pagination={paginationProps}
                    rowKey={(item) => item.Hash}
                />
            </Spin>
        </div>
        <Footer />
    </div>
}

export default Block