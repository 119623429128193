import React, { useState, useEffect } from "react";
import { urls, getSubStrNum, time, idforDescRegexp } from "../../../utils/utils";
import { Spin, message } from "antd";
import { Link } from "react-router-dom";
function LatestIdfor() {
    const [latestIdfor, setIdfor] = useState([]);
    const [spin, setspin] = useState(true);
    const getData = () => {
        fetch(urls + "latest-ontids")
            .then(res => res.json())
            .then(res => {
                if (res.Desc === "SUCCESS") {
                    setIdfor(res.Result.List)
                    setspin(false)
                } else {
                    message.error(res.Desc)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        getData();
        const timer = setInterval(() => {
            getData()
        }, 10000);

        return () => clearInterval(timer)
    }, [])
    return <div className="latest-data-item">
        <Link to="/Idfor/list">
            <div className="latest-data-item-title">
                <span>最新事件</span>
                <img src={require("../../../assets/img/right.png")} alt="" />
            </div>
        </Link>
        <Spin spinning={spin}>
            {
                latestIdfor.map((item, key) => {
                    const times = time(item.Txntime);
                    return <div className="latest-data-item-value" key={key}>
                        <Link to={`/idfor/idforDetail/${item.Ontid}`}>
                            <p>
                                <span className="left-value" style={{ letterSpacing: "1px" }}>{getSubStrNum(item.Ontid)}</span>
                                <span className="idfor-status">{
                                    idforDescRegexp(item.Description)
                                }</span>
                            </p>
                            <p>
                                <span className="left-value"></span>
                                <span className="right-value">{times}</span>
                            </p>
                        </Link>
                    </div>
                })
            }
        </Spin>
    </div>
}

export default LatestIdfor