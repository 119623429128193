import React, { useState, useEffect } from "react";
import Head from "../../../compnents/head";
import Footer from "../../../compnents/footer";
import { Spin, PageHeader, message } from "antd";
import { urls, formatDate, copy, goBack, transactionDescReg } from "../../../utils/utils";
function TransactionDetail(props) {
    const [blockHeight, setBlockHeight] = useState(1);
    const [txnType, setTxnType] = useState("");
    const [txnHash, setTxnHash] = useState("");
    const [desc, setDesc] = useState("");
    const [txntime, setTxntime] = useState(1);
    const [confirm, setconfirm] = useState(1);
    const [spinning, setspinning] = useState(true);
    // 监听页面URL变化，
    window.addEventListener("popstate", function () {
        setspinning(true);
        document.location.reload();
    })
    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        const txnhash = props.match.params.transaction
        fetch(urls + `transaction/${txnhash}`)
            .then(res => res.json())
            .then(res => {
                if (res.Desc === "SUCCESS") {
                    setBlockHeight(res.Result.Height);
                    setTxnHash(res.Result.Txnhash);
                    setTxnType(res.Result.Txntype);
                    setDesc(res.Result.Description);
                    setTxntime(res.Result.Txntime);
                    setconfirm(res.Result.Confirmflag);
                    setspinning(false);
                } else {
                    message.err(res.Desc)
                }
            }).catch(err => console.log(err))
    }
    return <div className="detail">
        <div className="top-head">
            <Head />
        </div>
        <Spin spinning={spinning}>
            <div className="transactionDetail-content transactionDetail">
                <div className="item">
                    <PageHeader>
                        <img src={require("../../../assets/img/left.png")} alt="left" className="title-icon" onClick={() => {
                            goBack()
                        }} style={{ cursor: "pointer" }} />
                    交易详情
               </PageHeader>
                    <div className="detail-container">
                        <p><span>哈希：</span> <span>{txnHash}  <img src={require("../../../assets/img/copy.png")} alt="复制" onClick={() => { copy(txnHash) }} style={{ cursor: "pointer" }} /></span></p>
                        <p><span>区块高度：</span><span>{blockHeight}</span></p>
                        <p><span>生成时间：</span><span>{formatDate(txntime * 1000)}</span></p>
                        <p><span>交易类型：</span><span>{txnType}</span></p>
                        <p><span>交易描述：</span><span>{transactionDescReg(desc)}</span></p>
                        <p><span>交易状态：</span> <span className={confirm ? "transaction-success" : "transaction-fail"}>{confirm === 1 ? "交易成功" : "交易失败"}</span></p>
                    </div>
                </div>
            </div>
        </Spin>
        <Footer />
    </div>
}
export default TransactionDetail
