import React, { useState, useEffect } from "react";
import Head from "../../../compnents/head";
import Footer from "../../../compnents/footer"
import "./index.css";
import { PageHeader, Table, message, Spin } from "antd";
import { Link } from "react-router-dom";
import { formatDate, urls, copy, goBack } from "../../../utils/utils";

function BlockDetail(props) {
    const [height, setHeight] = useState(0);
    const [size, setSize] = useState(1);
    const [hash, setHash] = useState("");
    const [consensusData, setconsensusData] = useState("");
    const [blockTime, setblockTime] = useState("");
    const [Nextblock, setNextblock] = useState("");
    const [Prevblock, setPrevblock] = useState("");
    const [bookKeeper, setbookKeeper] = useState([]);
    const [txnList, settxnList] = useState([]);
    const [spinning, setspinning] = useState(true);
    const [total, setTotal] = useState(0);
    //监听页面URL的变化
    window.addEventListener('popstate', function (event) {
        setspinning(true)
        document.location.reload()
    });
    useEffect(() => {
        getData();
    }, [])
    const getData = () => {
        fetch(urls + `block/${props.match.params.height}`)
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.Desc === "SUCCESS") {
                    settxnList(data.Result.TxnList);
                    setHeight(data.Result.Height);
                    setSize(data.Result.Blocksize);
                    setHash(data.Result.Hash);
                    setconsensusData(data.Result.Consensusdata);
                    setblockTime(data.Result.Blocktime);
                    setNextblock(data.Result.Nextblock);
                    setPrevblock(data.Result.Prevblock);
                    setbookKeeper(data.Result.Bookkeeper.split("&"))
                    setTotal(data.Result.Txnnum)
                    setspinning(false)
                } else {
                    message.error(data.Desc)
                }
            }).catch(err => console.log(err))
    }

    const columns = [
        {
            title: '交易哈希',
            dataIndex: 'Txnhash',
            key: 'Txnhash',
            render: (text) => <Link style={{ color: "rgba(0,0,0,0.8)" }} to={`/transaction/transactionDetail/${text}`}>{text}</Link>
        },
        {
            title: '块高',
            key: 'Height',
            dataIndex: 'Height',
        },
        {
            title: "状态",
            key: "Confirmflag",
            dataIndex: "Confirmflag",
            render: (text) => <p style={{ margin: "0" }}>{text === 1 ? <span style={{ color: "#00A0E8", fontSize: "16px", letterSpacing: "1px" }}>交易成功</span> : <span style={{ color: "rgba(0,0,0,0.25)", fontSize: "16px" }}>交易失败</span>}</p>
        },
        {
            title: '时间',
            dataIndex: "Txntime",
            key: 'Txntime',
            render: (time) => (<span>{formatDate(time * 1000)}</span>)
        }
    ]
    const paginationProps = {
        showSizeChanger: false,
        showTotal: () => `共${total}条`,
        total: total,
    };

    return <div className="blockDetail  detail">
        <div className="top-head">
            <Head />
        </div>
        <Spin spinning={spinning}>
            <div className="blockDetail-content">
                <div className="item">
                    <PageHeader>
                        <img src={require("../../../assets/img/left.png")} alt="left" className="title-icon" onClick={() => { goBack() }} style={{ cursor: "pointer" }} />
                    区块详情
                </PageHeader>
                    <div className="item-container">
                        <p className="blockDetail-text"><span>区块高度：</span>  <span>{height}  <img src={require("../../../assets/img/copy.png")} alt="复制" onClick={() => { copy(height) }} style={{ cursor: "pointer" }} /></span></p>
                        <p className="blockDetail-text"><span>生成时间：</span>  <span>{formatDate(blockTime * 1000)}  </span></p>
                        <p className="blockDetail-text"><span>区块大小：</span>  <span> {size} bytes</span></p>
                        <p className="blockDetail-text"><span>共识数据：</span>  <span>{consensusData} </span></p>
                        <p className="blockDetail-text"><span>哈希：</span>  <span>{hash} </span></p>
                        <div className="blockDetail-text"><span>记账人地址：</span>  <div>{bookKeeper.map((item, key) => (<p key={key} style={{ margin: 0 }}>{item}</p>))}</div></div>
                        <p className="blockDetail-text"><span>上一区块：</span>  <span> <Link to={`/block/blockDetail/${Prevblock}`} style={{ color: "rgba(0, 0, 0, 0.95)" }}>{Prevblock} </Link></span></p>
                        <p className="blockDetail-text"><span>下一区块：</span>  <span><Link to={`/block/blockDetail/${Nextblock}`} style={{ color: "rgba(0, 0, 0, 0.95)" }}>{Nextblock}</Link> </span></p>
                    </div>

                </div>
                <div className="item">
                    <PageHeader>
                        交易
                </PageHeader>
                    <Table
                        className="blockDetail-table"
                        columns={columns}
                        dataSource={txnList}
                        pagination={paginationProps}
                        rowKey={(item) => item.Txnhash}
                    />
                </div>
            </div>
        </Spin>
        <Footer />
    </div>
}

export default BlockDetail