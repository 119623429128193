import React, { useState } from "react";
import Line from "../../compnents/chart";
import Footer from "../../compnents/footer"
import Head from "../../compnents/head";
import Banner from "./banner";

import Statisatical from "../../compnents/statistical/statistical";
import LatestBlock from "../idforBrowser/block/latest_block";
import LatestTransaction from "../idforBrowser/transaction/latest_transaction";
import LatestIdfor from "../idforBrowser/idfor/latest_idfor";
import "./index.css";
import { BannerData } from "./data"
function Home() {
    const [top, setTop] = useState(false);
    window.addEventListener('scroll', function (event) {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 0) {
            setTop(true)
        } else {
            setTop(false)
        }
    });
    window.addEventListener("popstate", function () {
        window.scrollTo(0, 0);
        console.log(document.documentElement.clientWidth)
    });
    window.addEventListener("resize", () => {
        console.log(document.documentElement.clientWidth);

    });
    return <div className="cover">
        <div className={top ? "head-shadow" : ""}>
        </div>
        <div className="cover-content" >
            <div className="idfor-banner">
                <Head />
                <Banner key="banner" dataSource={BannerData} />
                {/* <div className="cover-title">
                    <h1>身份链浏览器</h1>
                    <p>IDFOR BROWSER</p>
                    <img src={require("../../assets/img/banner.png")} alt="" />
                </div> */}
            </div>
            <Statisatical />
            <div className="generate-block-time">
                <span>出块时间</span>
                <Line />
            </div>

            <div className="latest-data">
                <img src={require("../../assets/img/particles.png")} alt="颗粒背景" className="particles" />
                <div className="latest-data-container">
                    {/* 最新的5个区块 */}
                    <LatestBlock />
                    {/* 最新5个交易 */}
                    <LatestTransaction />
                    {/* 最新的5个数字身份 */}
                    <LatestIdfor />
                </div>
                <Footer />
            </div>
        </div>
    </div>

}

export default Home
