import React, { useEffect, useState } from "react";
import { urls, time, getSubStrNum } from "../../../utils/utils";
import { Spin, message } from "antd";
import { Link } from "react-router-dom";

function LatestTransaction() {
    const [transaction, setTransaction] = useState([]);
    const [spin, setspin] = useState(true);
    useEffect(() => {
        getData()
        const timer = setInterval(() => {
            getData()
        }, 10000);
        return () => { clearInterval(timer) }
    }, [])
    const getData = () => {
        fetch(urls + "last-transactions")
            .then(res => res.json())
            .then(res => {
                if (res.Desc === "SUCCESS") {
                    setTransaction(res.Result.List);
                    setspin(false)
                } else {
                    message.error(res.Desc)
                }
            }).catch(err => {
                console.log(err)
            })
    }
    return <div className="latest-data-item">
        <Link to="/transaction/list">
            <div className="latest-data-item-title">
                <span>最新交易</span>
                <img src={require("../../../assets/img/right.png")} alt="" />
            </div>
        </Link>
        <Spin spinning={spin}>
            {
                transaction.map((item, key) => {

                    const times = time(item.Txntime)
                    return <div className="latest-data-item-value" key={key}>
                        <Link to={`/transaction/transactionDetail/${item.Txnhash}`}>
                            <p>
                                <span className="left-value">{getSubStrNum(item.Txnhash)}</span>
                                <span className={`transaction-status  ${item.Confirmflag === 1 ? "transaction-success" : "transaction-failure"}`}>{item.Confirmflag === 1 ? "交易成功" : "交易失败"}</span>
                            </p>
                            <p>
                                <span className="left-value">交易类型  {item.Txntype}</span>
                                <span className="right-value">{times}</span>
                            </p>
                        </Link>
                    </div>
                })
            }
        </Spin>
    </div>
}
export default LatestTransaction