import React from 'react';
import { ConfigProvider } from "antd";
import { Switch, Route, HashRouter, Redirect } from "react-router-dom";
import Home from "./views/home";
import Block from "./views/idforBrowser/block/block";
import BlockDetail from './views/idforBrowser/block/blockDetail';
import Transaction from "./views/idforBrowser/transaction/transaction";
import TransactionDetail from "./views/idforBrowser/transaction/transactionDetail"
import Idfor from "./views/idforBrowser/idfor/idfor";
import IdforDetail from "./views/idforBrowser/idfor/idforDetail";
import Law from "./views/law/law";
import zhCN from 'antd/es/locale/zh_CN';
function App() {
  return (<ConfigProvider locale={zhCN}>
    <HashRouter>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/block/list" component={Block} />
        <Route path="/block/blockDetail/:height" component={BlockDetail} />
        <Route path="/block/blockDetail&:height" component={BlockDetail} />
        <Route path="/transaction/list" component={Transaction} />
        <Route path="/transaction/transactionDetail/:transaction" component={TransactionDetail} />
        <Route path="/transaction/transactionDetail&:transaction" component={TransactionDetail} />
        <Route path="/Idfor/list" component={Idfor} />
        <Route path="/idfor/idforDetail/:idfor" component={IdforDetail} />
        <Route path="/idfor/idforDetail&:idfor" component={IdforDetail} />
        <Route path="/law" component={Law} />
        {/* 匹配不到以上的路由，则重定向到/home */}
        <Redirect to="/home" component={Home} />
      </Switch>
    </HashRouter>
  </ConfigProvider>
  );
}

export default App;
