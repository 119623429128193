import banner from "../../assets/img/banner.png";

export const NavData = {

}
export const BannerData = {
    wrapper: { className: 'cover-title' },
    page: { className: 'test' },
    childWrapper: {
        className: 'banner-title-wrapper',
        children: [
            { name: 'title', children: '身份链浏览器', className: 'banner-title' },
            {
                name: 'explain',
                className: 'banner-explain',
                children: 'IDFOR BROWSER',
            },
        ],
    },
    image: {
        className: 'banner5-image',
        children:
            banner,
    },
}

