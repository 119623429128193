import React from "react";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one"
import { getChildrenToRender } from "../../utils/utils"
function Banner(props) {
    const { ...tagProps } = props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
        queue: 'top',
        one: {
            y: '+=300',
            type: 'from',
            ease: 'easeOutQuad',
            duration: "2000",
            // blur: "20px"
            // grayScale: 1,
            // sepia: 1,
            // hueRotate: '90deg'
        },
    };
    return <div className="cover-title">
        <QueueAnim
            key="text"
            type="scaleX"
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
        >
            {
                dataSource.childWrapper.children.map(getChildrenToRender)
            }
        </QueueAnim>
        {/* <h1>身份链浏览器</h1>
            <p>IDFOR BROWSER</p> */}
        <TweenOne animation={animType.one}>
            <img src={dataSource.image.children} alt="" />
        </TweenOne>
    </div>
}


export default Banner 