import React from "react";
import { Link } from "react-router-dom"
import "./index.css"
function Footer() {
    return <div className="footer-container">
        <p>Copyright © 2017-2020   </p>&nbsp;&nbsp;
        {/* <p>贵州梵华星云数据资源管理有限公司</p>&nbsp;&nbsp; */}
        <p>贵州远东数字科技有限公司</p>&nbsp;&nbsp;
        <p><a href="http://www.beian.gov.cn/" target="_blank" style={{ color: "rgba(0, 0, 0, 0.35)" }} rel="noopener noreferrer">黔ICP备18004561号</a></p>&nbsp;&nbsp;
        <p>黔网信备52011519788716900017号</p>&nbsp;&nbsp;
        <p><Link to="/law">法律声明</Link></p>

    </div>
}

export default Footer
