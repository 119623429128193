import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Input, message, Drawer } from "antd";
import "./index.css";
import logo from "../../assets/img/logo.png";
import { useState } from "react";
function Head(props) {
    const [input, setInput] = useState("");
    const [visible, setvisible] = useState(false);
    const isNumber = (val) => {
        var regPos = /^\d+(\.\d+)?$/; //非负浮点数
        if (regPos.test(val)) {
            return true;
        } else {
            return false;
        }
    }

    const onChange = (e) => {
        setInput(e.target.value)
    }
    const search = (param) => {
        if ((param !== "" && !isNumber(param))) {
            switch (param.length) {
                case 64:
                    props.history.push(`/transaction/transactionDetail/${param}`);
                    break;
                case 44:
                    props.history.push(`/idfor/idforDetail/${param}`);
                    break;
                default:
                    message.warning({
                        content: '请输入正确内容',
                        className: 'custom-class',
                        style: {
                            marginTop: '20vh',
                        },
                    })
                    console.log(isNumber(param))
            }
        } else if (isNumber(param)) {
            props.history.push(`/block/blockDetail/${param}`);
        } else {
            message.warning({
                content: '请输入内容',
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            })
            console.log(isNumber(input))
        }
    }
    const onSearch = () => {
        search(input)
    }
    const onPressEnter = (e) => {
        search(e.target.value)
    }
    const onClose = () => {
        setvisible(false)
    }
    const openDrawer = () => {
        setvisible(true)
    }
    return <div>
        <header className="cover-head">
            <div className="head-left">
                <Link to="/home"><img src={logo} alt="idforlogo" className="logo-img" /></Link>
                <div className="head-search">
                    <img src={require("../../assets/img/search-icon.png")} alt="搜索，查询" className="search-icon" />
                    <Input placeholder="数字身份，区块链高度，交易哈希" onBlur={onChange} onPressEnter={onPressEnter} />
                    <div className="search-btn" onClick={onSearch}>查询</div>
                </div>
            </div>
            <div className="top-nav">
                <Link to="/home" className="n-item">
                    首页
                         </Link>
                <Link to="/block/list" className="n-item">
                    区块
                         </Link>
                <Link to="/transaction/list" className="n-item">
                    交易
                         </Link>
                <Link to="/idfor/list" className="n-item">数字身份</Link>
            </div>
            <div className="right-btn">
                <img src={require("../../assets/img/menu.png")} alt="" className="menu" onClick={openDrawer} />
            </div>
            <Drawer
                className="top-drawer"
                closable={true}
                onClose={onClose}
                visible={visible}
                placement="top">
                <Link to="/home" className="n-item">
                    首页
                         </Link>
                <Link to="/block/list" className="n-item">
                    区块
                         </Link>
                <Link to="/transaction/list" className="n-item">
                    交易
                         </Link>
                <Link to="/idfor/list" className="n-item">数字身份</Link>
            </Drawer>
        </header>
    </div>
}

export default withRouter(Head)