import React, { useState, useEffect } from "react";
import Head from "../../../compnents/head";
import Footer from "../../../compnents/footer"
import "./idfor.css"
import { PageHeader, Table, Spin } from "antd";
import { Link } from "react-router-dom";
import { urls, formatDate, copy, goBack } from "../../../utils/utils";
function IdforDetail(props) {
    const [owners, setowners] = useState([]);
    const [txnList, setTxnList] = useState([]);
    const [idfor, setIdfor] = useState("");
    const [spinning, setspinning] = useState(true);

    //监听页面URL的变化
    window.addEventListener("popstate", function () {
        setspinning(true);
        document.location.reload();
    })
    useEffect(() => {
        getData(10, 1)
    }, [])
    const getData = (pagesize, page) => {
        const idfor = props.match.params.idfor
        fetch(urls + `ontid/${idfor}/${pagesize}/${page}`)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.Desc === "SUCCESS") {
                    setTxnList(res.Result.TxnList);
                    setowners(res.Result.Ddo.Owners)
                    setIdfor(res.Result.Ddo.OntId);
                    setspinning(false);
                }
            })
            .catch(err => console.log(err))
    }
    const columns = [
        {
            title: "哈希",
            dataIndex: "Txnhash",
            key: "Txnhash",
            render: (text) => (<Link to={`/transaction/transactionDetail/${text}`}><span style={{ color: "rgba(0,0,0,.8)" }}>{text}</span></Link>)
        }, {
            title: "块高",
            dataIndex: "Height",
            key: "Height",
            render: (text) => (<Link to={`/block/blockDetail/${text}`}><span style={{ color: "rgba(0,0,0,.8)" }}>{text}</span></Link>)
        }, {
            title: "时间",
            dataIndex: "Txntime",
            key: "Txntime",
            render: (text) => (<span>{formatDate(text * 1000)}</span>)
        }
    ]
    return <div className="idforDetail  detail">
        <div className="top-head">
            <Head />
        </div>
        <div className="idforDetail-content">
            <Spin spinning={spinning}>
                <div className="item">
                    <PageHeader>
                        <img src={require("../../../assets/img/left.png")} alt="left" className="title-icon" onClick={() => { goBack() }} style={{ cursor: "pointer" }} />
数字身份详情
                </PageHeader>
                    <div className="item-container">
                        <p className="idforDetail-text" style={{ marginBottom: "28px" }}><span>数字身份：</span><span>{idfor}   <img src={require("../../../assets/img/copy.png")} alt="复制" onClick={() => { copy(idfor) }} style={{ cursor: "pointer" }} /></span></p>
                        {
                            owners.map((item, key) => {
                                return <div key={key} >

                                    <p className="idforDetail-text">
                                        <span>Type：</span>
                                        <span >{item.Type}</span>
                                    </p>
                                    <p className="idforDetail-text">
                                        <span>Curve：</span>
                                        <span>{item.Curve}</span>
                                    </p>
                                    <p className="idforDetail-text">
                                        <span>Value：</span>
                                        <span>{item.Value}</span>
                                    </p>
                                    <p className="idforDetail-text"><span>PublicKeyId：</span><span>{item.PubKeyId}</span></p>
                                </div>
                            })
                        }

                    </div>
                </div>
                <div className="item">
                    <PageHeader>
                        在此数字身份上的事件
                </PageHeader>
                    <Table
                        className="idforDetail-table"
                        columns={columns}
                        dataSource={txnList}
                        rowKey={(item) => item.Txnhash}
                    />
                </div>

            </Spin>
        </div>
        <Footer />
    </div>
}

export default IdforDetail