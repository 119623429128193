import React, { useState, useEffect } from "react";
import Head from "../../../compnents/head";
import Footer from "../../../compnents/footer"
import "./idfor.css"
import { Link } from "react-router-dom";
import { Table, Popover, message, Spin } from "antd";
import { urls, formatDate, getSubStrNum, idforDescRegexp } from "../../../utils/utils";
function Idfor() {
    const [idfor, setIdfor] = useState([]);
    const [total, setTotal] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [spinning, setspinning] = useState(true);
    useEffect(() => {
        getData(10, 1)
    }, [])
    const getData = (pagesize, page) => {
        fetch(urls + `ontids/${pagesize}/${page}`)
            .then(res => res.json())
            .then(res => {
                if (res.Desc === "SUCCESS") {
                    setIdfor(res.Result.List);
                    setTotal(res.Result.Total);
                    setPageSize(res.Result.PageSize);
                    setspinning(false)
                } else {
                    message.err(res.Desc)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    const columns = [
        {
            title: '数字身份',
            dataIndex: 'Ontid',
            key: 'Ontid',
            render: (ontid, record, index) => (<Link to={`/idfor/idforDetail/${ontid}/10/1`} style={{ color: "rgba(0,0,0,0.8)" }}><span >{ontid}</span></Link>)
        },
        {
            title: '哈希',
            dataIndex: 'Txnhash',
            key: 'Txnhash',
            render: (text) => (<Popover content={text} title="交易哈希"  ><span>{getSubStrNum(text)}</span></Popover>)
        },
        {
            title: '块高',
            key: 'Height',
            dataIndex: 'Height',
        },
        {
            title: "描述",
            key: "Description",
            dataIndex: "Description",
            render: (text) => idforDescRegexp(text)
        },
        {
            title: "时间",
            dataIndex: "Txntime",
            key: "Txntime",
            render: (time) => (<p style={{ margin: 0 }}>{formatDate(time * 1000)}</p>)
        }
    ];

    const changePage = (current) => {
        getData(pageSize, current);
        setspinning(true)
    };

    const onShowSizeChange = (current, pageSize) => {
        getData(pageSize, current);
        setPageSize(pageSize);
    }
    const paginationProps = {
        showSizeChanger: false,
        showTotal: () => `共${total}条`,
        pageSize: pageSize,
        total: total,
        pageSizeOptions: ['10', '20'],
        onChange: (current) => changePage(current),
        onShowSizeChange: (current, pageSize) => { onShowSizeChange(current, pageSize) }
    }
    return <div className="layout">
        <div className="top-head">
            <Head />
        </div>
        <Spin spinning={spinning}>
            <div className="list">
                <h2>最新数字身份</h2>
                <Table
                    className="idfor-table"
                    columns={columns}
                    rowKey={(item) => item.Txnhash}
                    dataSource={idfor}
                    pagination={paginationProps}
                />
            </div>
        </Spin>
        <Footer />
    </div>
}

export default Idfor