import React from "react";
import "./law.css";
import Head from "../../compnents/head";
import Footer from "../../compnents/footer"
export default function Law() {
    return <div className="layout">
        <div className="top-head">
            <Head />
        </div>
        <div className="law">
            <h1>法律申明</h1>
            <p>除非身份链【指身份链服务提供者运营】另行声明，身份链内的所有产品、技术、软件、程序及其他信息的所有权利均归身份链服务提供者所有。</p>
            <p>身份链的文字及/或标识，以及身份链的其他标识、徽记、产品和服务名称均为身份链服务提供者及/或其关联公司在中国和其他国家的商标，如有宣传、展示等任何使用需要，您必须取得身份链服务提供者及/或其关联公司事先书面授权。</p>

            <h3>责任限制</h3>
            <p>鉴于身份链提供的信息服务属于信息由用户自行提供并上传，由用户对其提供并上传的信息承担相应法律责任，并对信息进行加密处理。</p>

            <p>身份链服务提供者对此另有约定的，将在相关的协议或其他法律文本中进行明确。</p>
        </div>
        <Footer />
    </div>
}