import React from "react"
import { notification, Button } from "antd";
import {
    SmileOutlined
} from '@ant-design/icons';
const createHistory = require("history").createHashHistory;
const history = createHistory();
/*
 2  * 功能：按千位逗号分割
 3  * 参数：s，需要格式化的数值.
 4  * 参数：type,判断格式化后是否需要小数位.
 5  * 返回：返回格式化后的数值字符串.
 6  */
export const formatNumber = function (s, type) {
    if (/[^0-9\.]/.test(s))
        return "0";
    if (s == null || s == "")
        return "0";
    s = s.toString().replace(/^(\d*)$/, "$1.");
    s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
    s = s.replace(".", ",");
    var re = /(\d)(\d{3},)/;
    while (re.test(s))
        s = s.replace(re, "$1,$2");
    s = s.replace(/,(\d\d)$/, ".$1");
    if (type == 0) { // 不带小数位(默认是有小数位)
        var a = s.split(".");
        if (a[1] == "00") {
            s = a[0];
        }
    }
    return s;
}


export const urls = "http://127.0.0.1:8660/api/v1/explorer/";
// export const urls = "https://explorer.idfor.fe-cred.com/api/v1/explorer/";
// export const urls = "http://58.42.4.39:20011/api/v1/explorer/";
const formatTime = function (n) {
    n = n.toString();
    return n[1] ? n : '0' + n
}
/**
 * 时间戳和日期转换
 * @param times 
 */
export const formatDate = function (times) {
    var now = new Date(times)
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var date = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    return year + "-" + formatTime(month) + "-" + formatTime(date) + " " + formatTime(hour) + ":" + formatTime(minute) + ":" + formatTime(second);
}
/**
 *   字符串前后保留8个字符
 * @param {*} str 
 */
export const getSubStrNum = function (str) {
    const str1 = str.substr(0, 8);
    const str2 = str.substr(str.length - 7, 8);
    const substr = str1 + "..." + str2;
    return substr
}

export const getSubStr = function (str, num) {
    const str1 = str.substr(0, num);
    const substr = str1 + "...";
    return substr
}

/**
 *   时间差
 * @param {*} startTIme 
 */
export const time = function (startTIme) {
    const endTIme = Date.parse(new Date().toString());
    const sond = endTIme / 1000 - startTIme;
    if (sond >= 24 * 60 * 60 * 30 * 12) {
        return parseInt((sond / (24 * 60 * 60 * 30 * 12)).toString()) + "年 前"
    }
    if (sond >= 24 * 60 * 60 * 30) {
        return parseInt((sond / (24 * 60 * 60 * 30)).toString()) + "月 前"
    }
    if (sond >= 24 * 60 * 60) {
        return parseInt((sond / (24 * 60 * 60)).toString()) + "天 前"
    }
    if (sond >= 3600) {
        return parseInt((sond / 3600).toString()) + "小时 前"
    }
    if (sond >= 60) {
        return parseInt((sond / 60).toString()) + "分钟 前"
    } else {
        return sond + "秒 前"
    }
}
/**
 * 复制函数
 * @param {*} str 
 */
export const copy = function (str) {
    const save = function (e) {
        e.clipboardData.setData('text/plain', str);
        e.preventDefault();
    };
    document.addEventListener("copy", save);
    document.execCommand("copy");
    document.removeEventListener("copy", save);
    notification.open({
        message: "复制成功！",
        placement: "bottomRight",
        icon: < SmileOutlined style={
            {
                color: '#108ee9'
            }
        }
        />,
    })
}
/**
 * 返回上一页
 */
export const goBack = () => {
    history.back();
}

/**
 *   idfor 描述检测
 * @param {*} str 
 */
export const idforDescRegexp = (str) => {
    const reg = RegExp(/Register/);
    if (reg.test(str)) {
        return "注册"
    } else {
        return "添加属性"
    }
}

/**
 * 交易描述检测
 */

export const transactionDescReg = (str) => {
    const reg = RegExp(/Register/);
    const addReg = RegExp(/Add/)
    if (reg.test(str)) {
        return "注册"
    } else if (addReg.test(str)) {
        return str
    } else {
        return ""
    }
}

/**
 * 判断图片URL
 */
export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
/**
 *   渲染组件
 * @param {*} item 
 * @param {*} i 
 */
export const getChildrenToRender = (item, i) => {
    let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div';
    tag = item.href ? 'a' : tag;
    let children = typeof item.children === 'string' && item.children.match(isImg)
        ? React.createElement('img', { src: item.children, alt: 'img' })
        : item.children;
    if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
        children = React.createElement(Button, {
            ...item.children
        });
    }
    return React.createElement(tag, { key: i.toString(), ...item }, children);
};
