import React, { useState, useEffect } from "react";
import { urls, time, formatNumber } from "../../../utils/utils";
import { message, Spin } from "antd";
import { Link } from "react-router-dom";
function LatestBlock() {
    const [latestBlock, setBlock] = useState([]);
    const [spin, setspin] = useState(true)
    useEffect(() => {
        getData();
        const timer = setInterval(() => {
            getData();
        }, 10000);
        return () => { clearInterval(timer) }
    }, [])
    const getData = () => {
        fetch(urls + `latest-blocks`)
            .then(res => res.json())
            .then(res => {
                if (res.Desc === "SUCCESS") {
                    setBlock(res.Result.List);
                    setspin(false)
                } else {
                    message.error(res.Desc)
                }
            })
            .catch(err => console.log(err))
    }
    return <div className="latest-data-item">

        <Link to="/block/list">
            <div className="latest-data-item-title">
                <span>最新区块</span>
                <img src={require("../../../assets/img/right.png")} alt="" />
            </div>
        </Link>

        <Spin spinning={spin}>
            {
                latestBlock.map((item, key) => {
                    const times = time(item.Blocktime);
                    return <div className="latest-data-item-value" key={key}>
                        <Link to={`/block/blockDetail/${item.Height}`}>
                            <p>
                                <span className="left-value">{formatNumber(item.Height, false)}</span>
                                <span className="right-value">{item.Txnnum} 交易</span>
                            </p>
                            <p>
                                <span className="left-value">{item.Blocksize}  字节</span>
                                <span className="right-value">{times}</span>
                            </p>
                        </Link>
                    </div>
                })
            }
        </Spin>
    </div>
}
export default LatestBlock