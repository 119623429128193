import React, { useState, useEffect } from "react";
import Head from "../../../compnents/head";
import Footer from "../../../compnents/footer"
import "./transaction.css"
import { Table, message, Spin } from "antd";
import { formatDate, urls } from "../../../utils/utils";
import { Link } from "react-router-dom"
function Transaction() {
    const [transaction, settransaction] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [spinning, setspinning] = useState(true)
    useEffect(() => {
        getData(10, 1)
    }, [])
    const getData = (pagesize, page) => {
        fetch(urls + `transactions/${pagesize}/${page}`)
            .then(res => res.json())
            .then(res => {
                if (res.Desc === "SUCCESS") {
                    settransaction(res.Result.List);
                    setPageSize(res.Result.PageSize);
                    setTotal(res.Result.Total);
                    setspinning(false);
                } else {
                    message.error(res.Desc);
                }
            }).catch(err => console.log(err))
    }
    const changePage = (current) => {
        setspinning(true);
        getData(pageSize, current);
    }
    const paginationProps = {
        showSizeChanger: false,
        showTotal: () => `共${total}条`,
        pageSize: pageSize,
        total: total,
        onChange: (current) => changePage(current),
    }
    const columns = [
        {
            title: '哈希',
            dataIndex: 'Txnhash',
            key: 'Txnhash',
            render: (text) => (<Link to={`/transaction/transactionDetail/${text}`} style={{ color: "rgba(0,0,0,0.8)" }}><span >{text}</span></Link>)
        },
        {
            title: '块高',
            key: 'Height',
            dataIndex: 'Height',
        },
        {
            title: "时间",
            dataIndex: "Txntime",
            key: "Txntime",
            render: (tiem) => (<p style={{ margin: 0 }}>{formatDate(tiem * 1000)}</p>)
        }
    ]
    return <div className="layout">
        <div className="top-head">
            <Head />
        </div>
        <div className="list">
            <Spin spinning={spinning}>
                <h2>最新交易</h2>
                <Table
                    className="transaction-table"
                    columns={columns}
                    dataSource={transaction}
                    rowKey={(item) => item.Txnhash}
                    pagination={paginationProps}
                />
            </Spin>
        </div>
        <Footer />
    </div>
}


export default Transaction