import React, { useState, useEffect } from "react";
import { urls } from "../../utils/utils";
import { Link } from "react-router-dom";
import TweenOne from "rc-tween-one";
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
TweenOne.plugins.push(Children);
function Statistical() {

    const [latestBlockHeight, setBlockHeight] = useState(1);
    const [latestTransaction, setTransaction] = useState(1);
    const [node, setNode] = useState(4);
    const [latestIdfor, setIdfor] = useState(1);
    useEffect(() => {
        getData()
        const timer = setInterval(() => {
            getData();
        }, 10000);
        return () => { clearInterval(timer) }
    }, [])
    const getData = () => {
        fetch(urls + `summary/blockchain/latest-info`)
            .then(res => res.json())
            .then(res => {
                if (res.Desc === "SUCCESS") {
                    setBlockHeight(res.Result.Height);
                    //setNode(res.Result.Nodecount);
                    setIdfor(res.Result.Ontidcount);
                    setTransaction(res.Result.Txncount);
                }
            })
            .catch(err => console.log(err))
    }
    return <div className="current-data">
        <div className="current-data-item">
            <Link to="/block/list"   >
                <img src={require("../../assets/img/block.png")} alt="当前块高" />
                <p className="current-data-item-title">当前区块高度</p>
                <p className="current-data-value">
                    <TweenOne
                        animation={{
                            Children: {
                                value: parseFloat(latestBlockHeight),
                                floatLength:
                                    parseFloat(latestBlockHeight) -
                                        Math.floor(parseFloat(latestBlockHeight)) >
                                        0
                                        ? 2
                                        : 0,
                                formatMoney: true,
                            },
                            duration: 2000,
                            ease: 'easeInOutCirc',

                        }}
                        component="span"
                    >
                        0
            </TweenOne>
                </p>
            </Link>
        </div>
        <div className="current-data-item">
            <Link to="/transaction/list">
                <img src={require("../../assets/img/transaction.png")} alt="当前交易笔数" />
                <p className="current-data-item-title">当前交易笔数</p>
                <p className="current-data-value">
                    <TweenOne
                        animation={{
                            Children: {
                                value: parseFloat(latestTransaction),
                                floatLength:
                                    parseFloat(latestTransaction) -
                                        Math.floor(parseFloat(latestTransaction)) >
                                        0
                                        ? 2
                                        : 0,
                                formatMoney: true,
                            },
                            duration: 2000,
                            ease: 'easeInOutCirc',

                        }}
                        component="span"
                    >
                        0
            </TweenOne>
                </p>
            </Link>
        </div>
        <div className="current-data-item">
            <img src={require("../../assets/img/node.png")} alt="共识节点" />
            <p className="current-data-item-title">共识节点数</p>
            <p className="current-data-value">
                <TweenOne
                    animation={{
                        Children: {
                            value: parseFloat(node),
                            floatLength:
                                parseFloat(node) -
                                    Math.floor(parseFloat(node)) >
                                    0
                                    ? 2
                                    : 0,
                            formatMoney: true,
                        },
                        duration: 1000,
                        ease: 'easeInOutCirc',

                    }}
                    component="span"
                >
                    0
            </TweenOne>
            </p>
        </div>
        <div className="current-data-item">
            <Link to="/Idfor/list">
                <img src={require("../../assets/img/idfor.png")} alt="数字身份总数" />
                <p className="current-data-item-title">数字身份总数</p>
                <p className="current-data-value">
                    <TweenOne
                        animation={{
                            Children: {
                                value: parseFloat(latestIdfor),
                                floatLength:
                                    parseFloat(latestIdfor) -
                                        Math.floor(parseFloat(latestIdfor)) >
                                        0
                                        ? 2
                                        : 0,
                                formatMoney: true,
                            },
                            duration: 2000,
                            ease: 'easeInOutCirc',

                        }}
                        component="span"
                    >
                        0
            </TweenOne>
                </p>
            </Link>
        </div>
    </div >

}

export default Statistical
