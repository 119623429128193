import React, { useEffect, useState } from "react";
// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts';
// 引入柱状图
import 'echarts/lib/chart/line';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import "./index.css"
import { urls } from "../../utils/utils";
import { Spin, message } from "antd";
//渲染图形
const myChart = (xData, yData) => {
    const myChart = echarts.init(document.getElementById("main"));
    myChart.setOption({
        baseOption: {
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "line",
                    crossStyle: {
                        color: "red"
                    }
                },
                formatter: function (params) {
                    var res = '<p>块高：' + params[0].name + '</p>'
                    for (var i = 0; i < params.length; i++) {
                        res += '<p> 出块时间间隔：' + params[i].data + '</p>'
                    }
                    return res;
                },
            },
            color: "#00A0E8",
            grid: {
                left: '2%',
                //距离左边的距离
                right: '1%', //距离右边的距离
                bottom: '10%', //距离下边的距离
                top: '8%', //距离上边的距离
            },
            xAxis: {
                type: 'category',
                data: xData,
                "axisLine": {       //X轴
                    "show": true,
                    lineStyle: {
                        color: "rgba(0,0,0,0.25)",
                    }
                },
                "axisTick": {       //轴刻度线
                    "show": false
                },
                "splitLine": {     //网格线
                    "show": false
                },
                axisLabel: {
                    interval: 4,
                    formatter: function (e) {
                        return e
                    },
                    textStyle: {
                        color: "rgba(0,0,0,0.25)",  //更改坐标轴文字颜色
                        fontSize: 16      //更改坐标轴文字大小
                    }
                }
            },
            yAxis: {
                title: "wge",
                type: 'value',
                axisLabel: {
                    //隐藏Y轴数据
                    // formatter: function () {
                    //     return "";
                    // }
                    textStyle: {
                        color: "rgba(0,0,0,0.25)",  //更改坐标轴文字颜色
                        fontSize: 16      //更改坐标轴文字大小
                    }
                },
                dispaly: "none",
                "axisLine": {       //y轴
                    "show": true,
                    lineStyle: {
                        color: "rgba(0,0,0,0.25)",
                    }
                },
                "axisTick": {       //y轴刻度线
                    "show": false
                },
                "splitLine": {     //网格线
                    "show": false
                },
                "show": true,
            },
            series: [{
                data: yData,
                type: 'line',
                smooth: true,
                symbol: "none",
            }]
        },
        media: [{
            query: { maxWidth: 1366 },
            option: {
                grid: {
                    left: '6%',
                    //距离左边的距离
                    right: '1%', //距离右边的距离
                    bottom: '10%', //距离下边的距离
                    top: '8%', //距离上边的距离
                },
                xAxis: {
                    axisLabel: {
                        interval: 8,
                        textStyle: {
                            color: "rgba(0,0,0,0.25)",  //更改坐标轴文字颜色
                            fontSize: 18   //更改坐标轴文字大小
                        }
                    }
                },
                yAxis: {
                    axisLabel: {
                        textStyle: {
                            color: "rgba(0,0,0,0.25)",  //更改坐标轴文字颜色
                            fontSize: 18     //更改坐标轴文字大小
                        }
                    }
                },
                series: [{
                    symbol: "none",
                }]
            }
        }, {
            query: { maxWidth: 959 },
            option: {
                grid: {
                    left: '6%',
                    //距离左边的距离
                    right: '1%', //距离右边的距离
                    bottom: '10%', //距离下边的距离
                    top: '8%', //距离上边的距离
                },
                xAxis: {
                    axisLabel: {
                        interval: 8,
                        textStyle: {
                            color: "rgba(0,0,0,0.25)",  //更改坐标轴文字颜色
                            fontSize: 10     //更改坐标轴文字大小
                        }
                    }
                },
                yAxis: {
                    axisLabel: {
                        textStyle: {
                            color: "rgba(0,0,0,0.25)",  //更改坐标轴文字颜色
                            fontSize: 10      //更改坐标轴文字大小
                        }
                    }
                },
                series: [{
                    symbol: "none",
                }]
            }
        }, {
            query: { maxWidth: 400, },
            option: {
                grid: {
                    left: '10%',
                    //距离左边的距离
                    right: '1%', //距离右边的距离
                    bottom: '10%', //距离下边的距离
                    top: '8%', //距离上边的距离
                },
            }
        }]
    });
};

function ApiTime() {
    const [spinning, setspinning] = useState(true)
    useEffect(() => {
        getData();
        const timer = setInterval(() => {
            getData();
        }, 10000);

        return () => { clearInterval(timer) }
    }, []);
    //监听页面变化重置
    window.addEventListener("resize", (e) => {
        // window.location.reload();
    })
    // console.log(document.body.clientWidth)
    const getData = () => {
        fetch(urls + `block/generatetime/30`)
            .then(res => res.json())
            .then(res => {
                const xData = [];
                const yData = [];
                if (res.Desc === "SUCCESS") {
                    setspinning(false)
                    res.Result.map((item) => {
                        xData.push(item.Height);
                        yData.push(item.GenerateTime);

                        return ""
                    })
                    myChart(xData, yData)
                } else {
                    message.error(res.Desc)
                }
            }).catch(err => {
                console.log(err)
            })
    }
    return <Spin spinning={spinning}><div id="main"></div></Spin>
}

export default ApiTime
